<template>
    <section class="main-content-section">
        <LoadingSpinner v-if="isLoading" />
        <div v-else class="grid grid-pad">
            <div class="col-1-1 center">
                <h2 class="page-title">{{ cookieContent[`title${selectedLanguage}`] }}</h2>
            </div>
            <TextPageBlock :text="cookieContent[`text${selectedLanguage}`]" />
        </div>
    </section>
</template>

<script>
import TextPageBlock from '@/components/TextPageBlock';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'Terms',
    components: { TextPageBlock, LoadingSpinner },

    computed: {
        ...mapGetters(['selectedLanguage', 'cookieContent', 'isLoading', 'metaTitle', 'cookiePolicy'])
    },

    created() {
        this.setDocumentTitle();

        if (this.cookieContent === null) {
            this.$store.dispatch('fetchCookieContent');
        }
    },

    updated() {
        this.setDocumentTitle();
    },

    methods: {
        setDocumentTitle() {
            document.title = this.cookiePolicy + this.metaTitle;
        }
    }
}
</script>