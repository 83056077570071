<template>
    <div class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
        <div class="textpage" v-html="text"></div>
    </div>
</template>

<script>
export default {
    name: 'TextPageBlock',
    props: { text: String }
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.textpage {
    border-radius: 8px;
    box-shadow: $shadow-1;
    padding: 6rem;
    background: $white;
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 300;
    
    p {
        margin: 0 0 2rem 0;
    }
    
    p a,
    li a {
        display: inline-block;
        color: inherit;
        text-decoration: underline;
    }

    &-title {
        font-size: 2.8rem;
        font-weight: 300;
        line-height: normal;
        display: block;
        text-align: center;
        margin: 0 0 2rem 0;
    }
}

@media only screen and (max-width: 400px) {
    .textpage {
        padding: 6rem 2rem;
    }
}
</style>